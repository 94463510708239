import React from "react";
import { rgba } from "polished";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title, Text, Span, List } from "../../components/Core";
import { device } from "../../utils";

const PlanCard = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  @media ${device.lg} {
    margin-top: 50px;
  }
`;



const About = ({ hero = true, bg = "dark", ...rest }) => {
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="align-items-center">
            {/* <Col lg="6">
              <div>
                <img src={imgPhoto} alt="folio" className="img-fluid" />
              </div>
            </Col> */}
            <Col lg="12">
              <div className="pl-lg-0 pt-5 pt-lg-0">
                <Title color="light" variant="secSm">
                We are an Auckland-based team of web designers, developers, and marketers.
                </Title>
                <Text
                  color="light"
                  className="mt-2 mt-lg-3"
                  css={`
                    line-height: 1.5;
                  `}
                >
                  In today's age, the world has become a global market, and your company needs a strong online presence. We're your one-stop digital shop for achieving real-world marketing results. Our team focuses on providing you with one-of-a-kind and cost-effective web design, development, SEO, hosting, and support services. Now, you can have enough time to focus on more relevant things while your website experiences improve without you even looking into it.
                </Text>
                
                </div>
              
            </Col>
          </Row>
          <Row className="align-items-stretch">
          <Col lg="4">
            <PlanCard color="light" className="p-4">
            <Text color="light" >Hobby</Text>
            <Title color="light" >$500</Title>
            <Text>We call this your business card on the internet. If you just need a site that has your basic contact details and looks good - this is for you.</Text>
            </PlanCard>
          </Col>
          <Col lg="4">
            <PlanCard color="light" className="p-4">
            <Text color="light" >Startup</Text>
            <Title color="light" >$1000</Title>
            <Text>This is the site that the majority of our clients go for. A full site, with maximum 10 pages, additional pages can come at reasonable cost. More information and pages means better search results, so it's worth the extra investment.</Text>
            </PlanCard>
          </Col>
          <Col lg="4">
            <PlanCard color="light" className="p-4">
            <Text color="light">eCommerce</Text>
            <Title color="light" >$2000</Title>
            <Text>If you're looking to create an online store then you'll want to look at our gold package. We'll build you an easy to use online store that lets you upload and manage your products without a tech background. Our base cost of $2,000 includes a full site build and the uploading of the first 10 products. For additional products we'll figure out a method of bulk upload with you, or discuss any additional fees.</Text>
            </PlanCard>
          </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default About;
