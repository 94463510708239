import React from "react";
import { Link } from "gatsby";
import { Title } from "../Core";
import logoImg from "../../assets/image/svg/nulane.svg";
import styled from "styled-components"
const ImgRight = styled.img`
  max-width: 200px;
  height: 30px;
`;
const Logo = ({ color = "front", height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>

      <ImgRight src={logoImg} alt="Nulane" />
    </Link>
  );
};

export default Logo;
